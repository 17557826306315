import React from 'react'
import {useTranslation} from 'react-i18next'
import {Box, Container, List, ListItem, Theme, Typography} from '@mui/material'

import homePage from '../../assets/images/spravka/1.webp'
import searchPage from '../../assets/images/spravka/2.webp'
import searchPage2 from '../../assets/images/spravka/3.webp'
import searchPage3 from '../../assets/images/spravka/4.webp'
import docPage from '../../assets/images/spravka/5.webp'
import docPage2 from '../../assets/images/spravka/6.webp'
import docPage3 from '../../assets/images/spravka/7.webp'
import docPage4 from '../../assets/images/spravka/8.webp'
import advanceSearch1Kg from '../../assets/images/spravka/advance-search1-kg.webp'
import advanceSearch2Kg from '../../assets/images/spravka/advance-search2-kg.webp'
import docInfoKg from '../../assets/images/spravka/doc-info-kg.webp'
import docPageKg from '../../assets/images/spravka/doc-page-kg.webp'
import homePageKg from '../../assets/images/spravka/home-kg.webp'
import linkedDocsKg from '../../assets/images/spravka/linked-docs-kg.webp'
import searchPageKg from '../../assets/images/spravka/search-page-kg.webp'
import sortKg from '../../assets/images/spravka/sort-kg.webp'

import { OpenData } from './open-data'

export const Synopsis = () => {
  const {t} = useTranslation()

  return (
    <Container sx={synopsis}>
      <OpenData />
      <>
        <Typography component='h1' mt={'40px'} variant={'h4'}>
          {t('reference.title')}
        </Typography>
        <Typography sx={{mt: 2}}>{t('reference.mainDescr')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.mainDescr2')}</Typography>
      </>
      <>
        <Typography variant={'h5'} sx={{mt: 2}}>
          {t('reference.mainPage')}
        </Typography>
        <Typography sx={{mt: 2}}>{t('reference.mainPage1')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.mainPage2')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.mainPage3')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.mainPage4')}</Typography>
        <ol style={{lineHeight: '25px'}}>
          <li>1. {t('categories')},</li>
          <li>2. {t('notRegister.title')},</li>
          <li>3. {t('pageNames.new-docs')},</li>
          <li>4. {t('pageNames.feedback')},</li>
          <li>5. {t('pageNames.statistics')}.</li>
        </ol>
        <Box sx={{textAlign: 'center', mt: '20px'}}>
          <Box
            component={'img'}
            src={changeImgLang(homePage, homePageKg)}
            alt='homePage'
            sx={imageStyle}
          />
        </Box>
      </>
      <>
        <Typography variant={'h5'} sx={{mt: 2}}>
          2. {t('reference.searchPage')}
        </Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage1')}</Typography>
        <List aria-label='basic-list'>
          <ListItem>{t('reference.searchPage2')}</ListItem>
          <ListItem>{t('reference.searchPage3')}</ListItem>
          <ListItem>{t('reference.searchPage4')}</ListItem>
        </List>
        <Box sx={{textAlign: 'center'}}>
          <Box
            component={'img'}
            src={changeImgLang(searchPage, searchPageKg)}
            alt='searchPage'
            sx={imageStyle}
          />
          <Box sx={adap}>
            <Box
              component={'img'}
              src={changeImgLang(searchPage2, advanceSearch1Kg)}
              alt='searchPage2'
              sx={imageStyle}
            />
            <Box
              component={'img'}
              src={changeImgLang(searchPage3, advanceSearch2Kg)}
              alt='searchPage3'
              sx={imageStyle}
            />
          </Box>
        </Box>
        <Typography variant={'h6'} sx={{mt: 2}}>
          {t('reference.searchPage5')}
        </Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage6')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage7')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage8')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage9')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage10')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage11')}</Typography>
        <Typography sx={{mt: 2}}>{t('reference.searchPage12')}</Typography>
        <Box sx={{textAlign: 'center'}}>
          <Box
            component={'img'}
            src={changeImgLang(docPage, sortKg)}
            alt='searchPage'
            sx={imageStyle}
          />
        </Box>
        <Typography sx={{mt: 2}}>{t('reference.searchPage13')}</Typography>
      </>
      <>
        <Typography variant={'h5'} sx={{mt: 2}}>
          3. {t('reference.docPage')}
        </Typography>
        <Typography sx={{mt: 2}}>{t('reference.docPage1')}</Typography>
        <List aria-label='basic-list'>
          <ListItem>1. {t('reference.docPage2')}</ListItem>
          <ListItem>2. {t('reference.docPage3')}</ListItem>
          <ListItem>3. {t('reference.docPage4')}</ListItem>
        </List>
        <Box sx={{textAlign: 'center', mt: 4}}>
          <Box
            component={'img'}
            src={changeImgLang(docPage2, docPageKg)}
            alt='docPage'
            sx={imageStyle}
          />
        </Box>
        <Typography sx={{mt: 2}}>{t('reference.docPage5')}</Typography>
        <Box sx={{textAlign: 'center', mt: 4}}>
          <Box
            component={'img'}
            src={changeImgLang(docPage3, docInfoKg)}
            alt='docPage2'
            sx={imageStyle}
          />
        </Box>
        <Typography sx={{mt: 2}}>{t('reference.docPage6')}</Typography>
        <Box sx={{textAlign: 'center', mt: 4}}>
          <Box
            component={'img'}
            src={changeImgLang(docPage4, linkedDocsKg)}
            alt='docPage3'
            sx={imageStyle}
          />
        </Box>
      </>
    </Container>
  )
}

const synopsis = () => ({
  mt: 4,
  mb: 4,
})

const adap = (theme: Theme) => ({
  display: 'flex',
  gap: 4,
  justifyContent: 'center',
  mt: 2,
  [theme.breakpoints.between('xs', 'md')]: {
    display: 'block',
  },
})

const imageStyle = (theme: Theme) => ({
  [theme.breakpoints.between(1100, 1500)]: {
    width: '800px',
  },
  [theme.breakpoints.between(500, 1100)]: {
    width: '500px',
  },
  [theme.breakpoints.between(300, 500)]: {
    width: '300px',
  },
})

function changeImgLang(imgRu: string, imgKg: string) {
  const lang = JSON.parse(localStorage.getItem('currentLang') as string)
  return lang?.lng === 'ru' ? imgRu || imgKg : imgKg || imgRu
}
