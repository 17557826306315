//api url
export const API_URL = process.env.REACT_APP_API_HOST
//get all docs
export const getAllDocuments = `${API_URL}/GetDocuments`
//get docs by id
export const getDocumentByCode = `${API_URL}/GetDocument?DocumentCode=`
//get data selects
export const getValueSelects = `${API_URL}/GetClassificatorItem?classificatorId=`
export const getValueSelectsParent = `${API_URL}/GetClassificatorItemParent?classificatorId=`
export const getValueSelectsChild = `${API_URL}/GetClassificatorItemChild?classificatorId=`
//get editionCode
export const getEditionCode = `${API_URL}/GetEdition?editionId=`
//popular requests
export const popularRequests = `${API_URL}/GetPopularRequest`
//download doc file
export const getFile = `${API_URL}/GetFile?refId=`
export const printFile = `${API_URL}/ConvertToPdf?refId1=`
//feedback
export const postFeedback = `${API_URL}/Feedbacks`
//document error
export const documentError = `${API_URL}/SpellingError`
//get media file
export const getMediaFile = `${API_URL}/GetMinioFile?fileName=`

export const getDocumentFile = `${API_URL}/GetDocumentFile?fileName=`
//get notional register
export const postNotionalRegister = `${API_URL}/Registries?`
export const getSelectSearchByRegister = `${API_URL}/References?id=`
//new docs
export const getNewDocs = `${API_URL}/GetNewDocuments`
//excel
export const getExcelFile = `${API_URL}/GetExelFile`
//excel new docs
export const getExcelFileNewDocs = `${API_URL}/GetExelFileForNewDocs`
//word
export const getWordFile = `${API_URL}/GetWordFile`
//word new docs
export const getWordFileNewDocs = `${API_URL}/GetWordFileForNewDocs`
//compare editions
export const compareEditions = `${API_URL}/compare?`
// convert to text
export const convertToText = `${API_URL}/ConvertToTxt?`
export const convertToTextLog = `${API_URL}/ConvertToTxtLog?`
//get statistic
export const getStatistics = `${API_URL}/GetFromGoogleAnalytics`
export const getStatisticsDiagram = `${API_URL}/GetStatistic`
//get statistic by
export const getStatisticBy = `${API_URL}/GetChildStatisticClassificators?StatisticClassificatorName=`
//download word for statistic
export const downloadWordForStatistic = `${API_URL}/GetDocStatistic`
//download excel for statistic
export const downloadExcelForStatistic = `${API_URL}/GetExelStatistic`
//recaptcha
export const checkRecaptcha = `${API_URL}/Signup`
//get feedbacks
export const getFeedbacks = `${API_URL}/FeedbacksByEsiEmail`
export const feedbackUsers = `${API_URL}/Questionnaire`
export const getFeedbackResponse = `${API_URL}/GetMessages`
export const getEsiDatas = (id: string | number | null) =>
  `${API_URL}/esi?guid=${id}`
// get edition history
export const getEditionHistory = `${API_URL}/GetEditionHistory`
// set elected doc
export const setElectedDoc = `${API_URL}/SetDocumentsByEsiEmail`
// get elected docs
export const getElectedDocs = `${API_URL}/GetDocumentsByEsiEmail`
// get elected docs
export const deleteElectedDoc = `${API_URL}/DocumentsByEsiEmail`
// esi logout
export const esiLogout = `${API_URL}/logout`
// esi login
export const esiLogin = (id: string | number) => `${API_URL}/esiauth?guid=${id}`
// history docs
export const historyDocs = `${API_URL}/PersonalAccount/GetRecentActionsByPin`
// history docs
export const docControl = `${API_URL}/PersonalAccount/PutDocumentToControl`
export const compareLang = `${API_URL}/ConvertToTxtLangCompare?refId=`
export const majorDocumets = `${API_URL}/GetMajorDocuments`
