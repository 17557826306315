import { useParams } from 'react-router-dom'
import { Box, CircularProgress, Container } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { $api } from 'src/utils'
import { API_URL } from 'src/utils/endpoints'


export const OpenDataGet = () => {
  const param = useParams()
  const route = param.id
  const routeLang = window.location.hash.split('lang=')[1]

  const {data, isLoading} = useQuery([route], async() => {
    return $api.get(routeLang ? `${API_URL}/${routeLang}/OpenData/${route}${window.location.search}` : `${API_URL}/OpenData/${route}${window.location.search}`)
  })
  
  return (
    <Container>
      {isLoading ? (
        <Box sx={{display: 'flex', mt: '50px', justifyContent:'center'}}>
          <CircularProgress size={50} />
        </Box>
      ) : (
        data?.data ? <pre style={{whiteSpace: 'pre-wrap'}}>{data?.headers['content-type'] === 'application/xml' ? data?.data : JSON.stringify(data?.data, null, 2)}</pre> : (
          <Box sx={{display: 'flex', justifyContent: 'center', mt: '100px'}}>
            Данные отсутствуют!
          </Box>
        )
      )}
    </Container>
  )
}
