import React from 'react'
import {Controller, useFormContext} from 'react-hook-form'
import {Box, TextField, Theme, Typography} from '@mui/material'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

interface Props {
  title: string
  registerText: string
  placeHolder: string
}

export const Date = ({title, registerText, placeHolder}: Props) => {
  const {control} = useFormContext()

  return (
    <Box sx={box}>
      <Typography>{title}</Typography>
      <Box sx={inputSelect}>
        <Controller
          control={control}
          name={registerText}
          render={({field: {name, onChange, ref, value = null}}) => {
            return (
              <DatePicker
                mask='__.__.____'
                label={placeHolder}
                // minDate={dayjs('2023')}
                renderInput={({inputProps, ...rest}) => {
                  const params = {
                    ...rest,
                    inputProps: {
                      ...inputProps,
                      placeholder: '__.__.____',
                    },
                  }
                  return (
                    <TextField
                      {...params}
                      sx={{width: '100%'}}
                      name={name}
                      variant='standard'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size='small'
                    />
                  )
                }}
                inputRef={ref}
                value={value}
                inputFormat='DD.MM.YYYY'
                onChange={onChange}
                toolbarFormat='__.__.____'
              />
            )
          }}
        />
      </Box>
    </Box>
  )
}

const box = (theme: Theme) => ({
  display: 'flex',
  alignItems: 'center',
  mt: 1,
  justifyContent: 'space-between',
  [theme.breakpoints.down(700)]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
})

const inputSelect = (theme: Theme) => ({
  width: '70%',
  [theme.breakpoints.down(700)]: {
    width: '100%',
  },
})
