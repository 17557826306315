/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link, useParams } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'

export const OpenDataHelp = () => {
    const param = useParams()
    const {lang} = useParams()
    const title = param?.id
  return (
    <>
      {pages(lang).map((i: PagesType) => title === i.page && (
        <Container sx={{mt: 4, mb: 4}} key={i.page}>
          <Typography sx={{fontSize: '20px'}}>{i.page}</Typography>
          <Box mt={'25px'}>
            <Typography sx={{fontSize: '20px'}}>Описание:</Typography>
            <Box>{i.descr}</Box>
          </Box>
          <Box mt={'25px'}>
            <Typography sx={{fontSize: '20px'}}>Список параметров:</Typography>
            <Box>{i.params?.map((i: any) => (
              <Box key={i.title}>
                <ul>
                  <li style={{marginBottom: '10px'}}>
                    {i.title} <br />
                    <Typography>{i.descr}</Typography>
                    {i.linkHelp ? (
                      <Link to={`/open-data/help/${i.linkHelp}/${lang}`}>Более подробная информация</Link>
                    ) : i.linkType ? (
                      <Link to={`/OpenData/${i.class ? 'GetClasses' : 'GetClassificators'}/${lang}?Type=${i.linkType}`}>Получить</Link>
                    ) : null}
                  </li>
                </ul>
              </Box>
            ))}</Box>
          </Box>
          <Box mt={'25px'}>
            <Typography sx={{fontSize: '20px'}}>Пример:</Typography>
            {i.example?.link?.map((ex: any, idx) => (
              <Box key={idx}>
                <Link to={`${ex}`}>{ex}</Link> <br />
              </Box>
            ))}
            <Typography>{i.example?.title}</Typography>
          </Box>
        </Container>
      ))}
    </>
  )
}

interface PagesType {
  page: string
  descr: string
  example?: {
    link?: string[]
    title?: string
  }
  params?: {
    title: string
    descr: string
    linkHelp?: string
    linkType?: string
    class?: boolean
  }[]
}

const pages = (lang?: string): Array<PagesType> => ([
  {
    page: 'CheckAvailable',
    descr: 'Проверка доступности сериса. Возвращает HTTP-статус код.',
    example: {
      link: [`/OpenData/CheckAvailable/${lang}`],
      title: ''
    }
  },
  {
    page: 'GetClasses',
    descr: 'Получение списка разделов.',
    example: {
      link: [`/OpenData/GetClasses.json/${lang}`],
      title: ''
    }
  },
  {
    page: 'GetProperties',
    descr: 'Получение списка всех свойств документов с характеристиками.',
    example: {
      link: [`/OpenData/GetProperties.xml/${lang}`, `/OpenData/GetProperties.json/${lang}`],
      title: ''
    }
  },
  {
    page: 'GetDocument',
    descr: 'Получение документа по коду с заданными свойствами.',
    params: [
      {
        title: 'Code: int, Обязательный',
        descr: 'Код документа',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
      {
        title: 'Property: string[]',
        descr: 'Массив свойств документа, которые нужно выгрузить. Если параметр не указан, то будут выгружены все свойства доступные объекту. Свойство Код документа (Code) выгружаеться всегда.',
        linkHelp: 'Property',
      },
      {
        title: 'IgnoreProperty: string[]',
        descr: 'Массив свойств документа, которые должны быть проигнорированы при выгрузке. Если используется параметр Property, то данный параметр будет проигнорирован.',
        linkHelp: 'IgnoreProperty',
      },
      {
        title: 'Editions: object',
        descr: 'Объект для настройки выгрузки редакций. Имеет три свойства. Для использование нужно указать хотя бы одно из них.',
        linkHelp: 'Editions',
      },
    ],
    example: {
      link: [`/OpenData/GetDocument/${lang}?Code=9&Property=Name&Property=Status&Property=Editions&Editions.Select=all&Editions.Data=last&Editions.Images.Select=all&Editions.Images.Data=none&Replace=anchors,images`],
      title: 'Выгрузка уголовно-процессуального кодекса со свойствами (полями) Name, Status, Editions, где выбранны все доступные редакции для этого документа, но с данными будет только последняя редакция. Изображения будут выгружены без данных (Base64), только названия файлов. У редакции будут заменены локальгые ссылки-якоря и ссылки на изображения'
    }
  },
  {
    page: 'GetDocuments',
    descr: 'Получение документов по кодам с заданными свойствами. Документы будут выгружены в порядке указания кодов.',
    params: [
      {
        title: 'Code:   int , Обязательный',
        descr: 'Код документа',
      },
      {
        title: 'Codes: int, Обязательный',
        descr: 'Список кодов документов указанных через запятую. Обязательный, если не указан ни один параметр Code',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
      {
        title: 'Property: string[]',
        descr: 'Массив свойств документа, которые нужно выгрузить. Если параметр не указан, то будут выгружены все свойства доступные объекту. Свойство Код документа (Code) выгружаеться всегда.',
        linkHelp: 'Property',
      },
      {
        title: 'IgnoreProperty: string[]',
        descr: 'Массив свойств документа, которые должны быть проигнорированы при выгрузке. Если используется параметр Property, то данный параметр будет проигнорирован.',
        linkHelp: 'IgnoreProperty',
      },
      {
        title: 'Editions: object',
        descr: 'Объект для настройки выгрузки редакций. Имеет три свойства. Для использование нужно указать хотя бы одно из них.',
        linkHelp: 'Editions',
      },
    ],
    example: {
      link: [`/OpenData/GetDocuments/${lang}?Code=1&Code=2&Code=3&Property=Name`, `/OpenData/GetDocuments/${lang}?Codes=1,2,3&Property=Name`],
      title: 'Выгрузка трех документов двумя способами.'
    }
  },
  {
    page: 'GetDocumentListByQuery',
    descr: 'Получение списка документов по запросу. Запрос определяется списком задаваемых параметров. Метод возвращает список документов, размер страницы, номер страницы и ID списка, который можно использовать в методе GetDocumentListById, чтобы повторно не выполнять поиск.',
    params: [
      {
        title: 'Name: string',
        descr: 'Название документа',
      },
      {
        title: 'Number: string',
        descr: 'Номер документа',
      },
      {
        title: 'NumberRegistration: string',
        descr: 'Номер регистрации в МЮ',
      },
      {
        title: 'EditionText: string',
        descr: 'Содержание документа',
      },
      {
        title: 'Classes: int[]',
        descr: 'Разделы. Для использования нужно указать один или несколько ID рaзделов. По умолчанию выбираются все разделы',
        linkType: 'Type',
        class: true
      },
      {
        title: 'Types: string[]',
        descr: 'Вид документа. Для использования нужно указать код одного или нескольких видов',
        linkType: 'Type',
      },
      {
        title: 'Authorities: string[]',
        descr: 'Органы. Для использования нужно указать код одного или нескольких органов',
        linkType: 'Authorities',
      },
      {
        title: 'SourcePublications: string[]',
        descr: 'Источники публикаций. Для использования нужно указать код одного или нескольких источников',
        linkType: 'SourcePublications',
      },
      {
        title: 'GeneralClassifiers: string[]',
        descr: 'Общеправовые классификаторы. Для использования нужно указать код одного или нескольких классификаторов',
        linkType: 'GeneralClassifiers',
      },
      {
        title: 'DateAdopted: DateRange',
        descr: 'Дата принятия',
        linkHelp: 'DateRange',
      },
      {
        title: 'DateRegistration: DateRange',
        descr: 'Дата вступления с силу',
        linkHelp: 'DateRange',
      },
      {
        title: 'DateLimit: DateRange',
        descr: 'Дата ограничения действия',
        linkHelp: 'DateRange',
      },{
        title: 'DateInclusion: DateRange',
        descr: 'Дата включения в Госреестр НПА',
        linkHelp: 'DateRange',
      },
      {
        title: 'DateInternalProcedures: DateRange',
        descr: 'Дата проведения внутригосударственных процедур',
        linkHelp: 'DateRange',
      },
      {
        title: 'DatePublication: DateRange',
        descr: 'Дата опубликования',
        linkHelp: 'DateRange',
      },
      {
        title: 'PublicationType: enum',
        descr: 'Устанавливает количество документов на страницу',
      },
      {
        title: 'PageSize: int',
        descr: 'Устанавливает количество документов на страницу',
      },
      {
        title: 'PageNumber: int',
        descr: 'Устанавливает количество документов на страницу',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
      {
        title: 'Property: string[]',
        descr: 'Массив свойств документа, которые нужно выгрузить. Если параметр не указан, то будут выгружены все свойства доступные объекту. Свойство Код документа (Code) выгружаеться всегда.',
        linkHelp: 'Property',
      },
      {
        title: 'IgnoreProperty: string[]',
        descr: 'Массив свойств документа, которые должны быть проигнорированы при выгрузке. Если используется параметр Property, то данный параметр будет проигнорирован.',
        linkHelp: 'IgnoreProperty',
      },
      {
        title: 'Editions: object',
        descr: 'Объект для настройки выгрузки редакций. Имеет три свойства. Для использование нужно указать хотя бы одно из них.',
        linkHelp: 'Editions',
      },
    ],
    example: {
      link: [`/OpenData/GetDocumentListByQuery/${lang}?DateAdopted.From=2000-01-01&IgnoreProperty=Editions&PageSize=10`],
      title: 'Выгрузит список первых 10 найденых документов принятых после 31 декабря 1999 года. У документов буду выгружены все свойства, кроме редакий (Editions).'
    }
  },
  {
    page: 'GetDocumentListById',
    descr: 'Получение списка документов по ID списка из метода GetDocumentListByQuery',
    params: [
      {
        title: 'DocumentListId: string (GUID), Обязательный',
        descr: 'ID списка документов, который возвращает метод',
        linkHelp: 'GetDocumentListByQuery'
      },
      {
        title: 'PageSize: int',
        descr: 'Устанавливает количество документов на страницу',
      },
      {
        title: 'PageNumber: int',
        descr: 'Устанавливает количество документов на страницу',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
      {
        title: 'Property: string[]',
        descr: 'Массив свойств документа, которые нужно выгрузить. Если параметр не указан, то будут выгружены все свойства доступные объекту. Свойство Код документа (Code) выгружаеться всегда.',
        linkHelp: 'Property',
      },
      {
        title: 'IgnoreProperty: string[]',
        descr: 'Массив свойств документа, которые должны быть проигнорированы при выгрузке. Если используется параметр Property, то данный параметр будет проигнорирован.',
        linkHelp: 'IgnoreProperty',
      },
      {
        title: 'Editions: object',
        descr: 'Объект для настройки выгрузки редакций. Имеет три свойства. Для использование нужно указать хотя бы одно из них.',
        linkHelp: 'Editions',
      },
    ],
    example: {
      link: [`/OpenData/DocumentListById/${lang}?DocumentListId=832259e9-379f-4402-83b7-68fb71812b25&Property=Name&PageSize=100&PageNumber=10`],
      title: 'Выгрузит список документов по ID списка из GetDocumentListByQuery с 1000 по 1100 содержащих только их коды и названия документов.'
    }
  },
  {
    page: 'GetReferenceDocuments',
    descr: 'Получение списка  документов ссылающихся на определенный документ',
    params: [
      {
        title: 'DocumentCode: int, Обязательный',
        descr: 'Код документа для которого нужо найти ссылающиеся на него документы',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
      {
        title: 'Property: string[]',
        descr: 'Массив свойств документа, которые нужно выгрузить. Если параметр не указан, то будут выгружены все свойства доступные объекту. Свойство Код документа (Code) выгружаеться всегда.',
        linkHelp: 'Property',
      },
      {
        title: 'IgnoreProperty: string[]',
        descr: 'Массив свойств документа, которые должны быть проигнорированы при выгрузке. Если используется параметр Property, то данный параметр будет проигнорирован.',
        linkHelp: 'IgnoreProperty',
      },
      {
        title: 'Editions: object',
        descr: 'Объект для настройки выгрузки редакций. Имеет три свойства. Для использование нужно указать хотя бы одно из них.',
        linkHelp: 'Editions',
      },
    ],
    example: {
      link: [`/OpenData/GetReferenceDocuments/${lang}?DocumentCode=1&Property=Name`],
      title: 'Выгрузка названий всех документов ссылающихся на конституцию.'
    }
  },
  {
    page: 'GetEditions',
    descr: 'Получение списка редакций для определенного документа documentCode',
    params: [
      {
        title: 'DocumentCode: int, Обязательный',
        descr: 'Код документа для которого нужо найти ссылающиеся на него документы',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
      {
        title: 'Select: string',
        descr: 'Определяет какие редакции должны быть выгружены. По умолчанию равен all - выгрузить всё. Можно указать список кодов редакций через запятую. Можно указать ключевое слово last - для выгрузки последней (по дате добавления) редакции. Ключевое слово help необходимо для выгрузки справки для документа (если такая имеется).',
      },
      {
        title: 'Data: string',
        descr: 'Устанавливает для каких редации будут выгружены данные (HTML), например можно выгрузить список всех редакций (без данных) для формирования списка. Способ использования точно такой же, как и у Select, но с но одним дополнительным ключом none - которые позваоляет вообще не выгужать данные. Соответсвено если в Select редакция не была выбрана, то даже указав её в Data не ничего выбрано не будет',
      },
      {
        title: 'Images: object',
        descr: 'Определяет для каких редакций будут выгружены изображения. Является объексом со своими параметрами Select и Data, которые используються абсолютно так же как и у Editions.',
      },
      {
        title: 'Replace: string',
        descr: 'Содержание редакций документов хранится в формате HTML и cсылки в нем имеют формат  <a href="cdb:код_документа">Ссылка на документ</a> Данный пераметр позволяет замененить ссылки (атрибуты href и src). Параметр имеет три ключевых слова, которые указываются через запятую: links - замена ссылок на другие документы на использование метода GetEditionHtml с соответсвующими параметрами. Позволяет свободно переходить по ссылкам. anchors - замена локальных (внутри документа) ссылок-якорей images - замена ссылок изображений (атрибут src у тега img) на использование метода GetEditionImage  с соответсвующеим параметрами.',
      },
    ],
    example: {
      link: [`/OpenData/GetEditions/${lang}?DocumentCode=1&Select=all&Data=10,100,500`],
      title: 'Выгрузка списка редакций для Констицтуции КР. Текст редакций будет выгружен только для трех.'
    }
  },
  {
    page: 'GetClassificators',
    descr: 'Получение списка классификаторов. Классификаторы имеют иерархическую структуру. Данные выгружаются соответсвенно также, т.е. одни классификаторы вложены в другие',
    params: [
      {
        title: 'Type: string[]',
        descr: 'Тип классификатора. Возможные значения, все названия свойств документа с типом classificator',
      },
      {
        title: 'Code: string[]',
        descr: 'Позволяет указать коды нужных классификаторов',
      },
      {
        title: 'MaxLevel: byte',
        descr: 'Позволяет задать максимальный уровень вложенности классификаторов. Например, если указать 0, то будут выгружены только родительские классификаторы, без вложенных. По умолчанию равен 255. Если у одного из классификатора есть вложенные, а по уровню они не должны выгружаться, то для удобства определения наличия вложенности возвращается пустой массив.',
      },
      {
        title: 'OnlyUsed: bool',
        descr: 'Только используемые. Позволяет выгружать только те классификаторы, на которые ссылкаются документы. По умолчанию равен false',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных.',
        linkHelp: 'Lang',
      },
    ],
    example: {
      link: [`/OpenData/GetClassificators/${lang}?Type=Keywords&Type=Type&OnlyParent=true`],
      title: 'Выгружает родитеские классификаторы двух видов: Keywords и Type'
    }
  },
  {
    page: 'GetEditionHtml',
    descr: 'Получение текста определенной редакции формате HTML для определенного документа',
    params: [
      {
        title: 'DocumentCode: int, Обязательный',
        descr: 'Код документа для которого нужо найти ссылающиеся на него документы',
      },
      {
        title: 'Editions: string',
        descr: 'Позволяет указать для какой редакции нужно выгрузить данные. Для это нужно либо указать код редакции, либо одно из двух ключевых слов: last - последняя редакция help - справка По умолчанию last',
      },
      {
        title: 'Replace: string',
        descr: 'Содержание редакций документов хранится в формате HTML и cсылки в нем имеют формат  <a href="cdb:код_документа">Ссылка на документ</a> Данный пераметр позволяет замененить ссылки (атрибуты href и src). Параметр имеет три ключевых слова, которые указываются через запятую: links - замена ссылок на другие документы на использование метода GetEditionHtml с соответсвующими параметрами. Позволяет свободно переходить по ссылкам. anchors - замена локальных (внутри документа) ссылок-якорей images - замена ссылок изображений (атрибут src у тега img) на использование метода GetEditionImage  с соответсвующеим параметрами.',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
    ],
    example: {
      link: [`/OpenData/GetEditionHtml/${lang}?DocumentCode=4`, `/OpenData/GetEditionHtml/${lang}?DocumentCode=90974&edition=last&replace=images`],
      title: 'Последняя редакция гражданского кодекса, со стандартными ссылками. Справка к документу 90974 с замененными ссылками на изображения'
    }
  },
  {
    page: 'GetEditionImage',
    descr: 'Загрузка изображения для определенного документа и редакции',
    params: [ 
      {
        title: 'DocumentCode: int, Обязательный',
        descr: 'Код документа для которого нужо найти ссылающиеся на него документы',
      },
      {
        title: 'Editions: string',
        descr: 'Позволяет указать для какой редакции нужно выгрузить данные. Для это нужно либо указать код редакции, либо одно из двух ключевых слов: last - последняя редакция help - справка По умолчанию last',
      },
      {
        title: 'Name: string',
        descr: 'Название файла',
      },
      {
        title: 'Lang: enum',
        descr: 'Язык выгружаемых данных',
        linkHelp: 'Lang',
      },
    ],
    example: {
      link: [`/OpenData/GetEditionImage/${lang}?DocumentCode=6&name=image001.jpg`],
    }
  },

  {
    page: 'Lang',
    descr: 'Язык выгружаемых данных. Имеет наивысший приоритет среди других языковых настроек (передача в заголовке Content-Language|Accept-Language, указание локализации в url) Варианты перечесления: Russian = 0 Kyrgyz = 1',
  },
  {
    page: 'Property',
    descr: 'Массив свойств документа, которые нужно выгрузить. Если параметр не указан, то будут выгружены все свойства доступные объекту. Свойство Код документа (Code) выгружаеться всегда.',
    example: {
      title: '?Property=Name&Property=Status&Property=Number'
    }
  },
  {
    page: 'IgnoreProperty',
    descr: 'Массив свойств документа, которые должны быть проигнорированы при выгрузке. Если используется параметр Property, то данный параметр будет проигнорирован.',
    example: {
      title: '?Property=Name&Property=Status&Property=Number'
    }
  },
  {
    page: 'Editions',
    descr: 'Объект для настройки выгрузки редакций. Имеет три свойства. Для использование нужно указать хотя бы одно из них.',
    params: [
      {
        title: 'Select: string',
        descr: 'Определяет какие редакции должны быть выгружены. По умолчанию равен all - выгрузить всё. Можно указать список кодов редакций через запятую. Можно указать ключевое слово last - для выгрузки последней (по дате добавления) редакции. Ключевое слово help необходимо для выгрузки справки для документа (если такая имеется).',
      },
      {
        title: 'Data: string',
        descr: 'Устанавливает для каких редации будут выгружены данные (HTML), например можно выгрузить список всех редакций (без данных) для формирования списка. Способ использования точно такой же, как и у Select, но с но одним дополнительным ключом none - которые позваоляет вообще не выгужать данные. Соответсвено если в Select редакция не была выбрана, то даже указав её в Data не ничего выбрано не будет',
      },
      {
        title: 'Images: object',
        descr: 'Определяет для каких редакций будут выгружены изображения. Является объексом со своими параметрами Select и Data, которые используються абсолютно так же как и у Editions.',
      },
      {
        title: 'Replace: string',
        descr: 'Содержание редакций документов хранится в формате HTML и cсылки в нем имеют формат  <a href="cdb:код_документа">Ссылка на документ</a> Данный пераметр позволяет замененить ссылки (атрибуты href и src).  Параметр имеет три ключевых слова, которые указываются через запятую: links - замена ссылок на другие документы на использование метода GetEditionHtml с соответсвующими параметрами.  Позволяет свободно переходить по ссылкам. anchors - замена локальных (внутри документа) ссылок-якорей images - замена ссылок изображений (атрибут src у тега img) на использование метода GetEditionImage  с соответсвующеим параметрами.',
      },
    ],
    example: {
      title: '?Edition.Select=100,90,last,help&Edition.Data=last,help&Edtion.Images.Select=all  Позволит выгрузить редакции с кодами 100 и 90, а так же последнюю и редакцию-справку.  Данные (HTML) будут выгружены только для последней редакции и справки.  А вот изображения будут выбраны для все четрыех выборанных редакций.'
    },
  },
  {
    page: 'DateRange',
    descr: 'Позволяет выполнять поиск по дате двумя способами: точно указав дату или указав диапозон. Имеет три свойства.',
    params: [
      {
        title: 'Exact: date',
        descr: 'Точная дата',
      },
      {
        title: 'From: date',
        descr: 'Начальная дата диапозона',
      },
      {
        title: 'To: date',
        descr: 'Конечная дата диапозона',
      }
    ],
    example: {
      title: '?DateRange.From=1900-01-01&DateAdopted.To=2000-01-01  -  выборка данных за сто лет'
    },
  },
])