import React, { useState } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import axios, { AxiosResponse } from 'axios'

import {
  getExcelFile,
  getExcelFileNewDocs,
  getWordFile,
  getWordFileNewDocs,
} from 'src/utils/endpoints'

interface Props {
  type: string
  bodyDatas: IForm
  icon: string
}

export const DownloadListDocs = ({ type, bodyDatas, icon }: Props) => {
  const isListDocs = location.pathname.includes('/list-docs')
  const [loadType, setLoadType] = useState<string>('')
  const isWord = type === 'word'

  const handleDownloadListDocs = async (type: string) => {
    const requestUrl =
      isWord && isListDocs
        ? getWordFile
        : isWord && !isListDocs
          ? getWordFileNewDocs
          : !isWord && isListDocs
            ? getExcelFile
            : !isWord && !isListDocs && getExcelFileNewDocs
    setLoadType(type)

    await axios
      .post(`${requestUrl}`, bodyDatas, {
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          }),
        )
        link.download = `list-documents`
        document.body.appendChild(link)
        link.click()
        setLoadType('')
      })
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Button onClick={() => handleDownloadListDocs(type)}>
        <img
          src={icon}
          title={isWord ? 'Выгрузка в Word файл' : 'Выгрузка в Excel файл'}
          width={60}
          alt='word'
          style={type === loadType ? { opacity: 0.5, position: 'relative' } : {}}
        />
        {type === loadType && (
          <CircularProgress sx={{ position: 'absolute' }} size={25} />
        )}
      </Button>
    </Box>
  )
}
