import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

export const OpenData = () => {
    const {t} = useTranslation()
  const {lang} = useParams()

  return (
    <Box>
        <Typography component='h1' variant={'h4'}>
          {t('Управление открытыми данными')}
        </Typography>
        <Typography sx={{mt: 2}}>{t('Для управления открытыми данными существуют несколько методов. Все они являются обычными GET-запросами, со своими параметрами')}</Typography>
        <Typography>
        Список методов:
        </Typography>
        <ul>
            {methods.map((i) => (
                <li key={i.title}>
                    <Link style={{color: 'black', textDecoration: 'none'}} to={`/open-data/help/${i.title}/${lang}`}>{i.title}</Link>
                </li>
            ))}
        </ul>
        <Typography component={'h2'}>Все методы позволяют выгружать данные на двух языках: кыргызском и русском. Существует три способа выбора языка:</Typography>
        <ul>
            <li>Указать язык в заголовке запроса Accept-Language или Content-Language ru или ky</li>
            <li>Указать локлизацию в адресе (url): ru-ru или ky-kg. Пример: <Link to={`/OpenData/GetDocument/${lang}?Code=1#lang=ky-kg`}>/ky-kg/OpenData/GetDocument?Code=1</Link> </li>
            <li>Указать параметр языка lang в запросе Kyrgyz или Russian. Пример: <Link to={`/OpenData/GetDocument/${lang}?Code=1&lang=Russian`}>/OpenData/GetDocument/1?lang=Russian</Link></li>
        </ul>
        <Typography>
            Способы указаны в порядке приорета по возрастанию: те что ниже, перекрывают тех что выше
            Если не указать язык, то данные будут выгружены на двух языках сразу
            Если при выгрузке с выбором одного из языков, не нашлось данных на этом языке, то они будут выгружены на другом языке
        </Typography>
        <Typography component={'h2'}>Так же все методы позволяют выгружать данные в двух форматах JSON и XML. Существует два сопособа выбора формата:</Typography>
        <ul>
            <li>Указать формат в заголовке запроса Accept или Content-Type: application/json или application/xml</li>
            <li>Указать формат в адресе (url) как расширение .json или .xml. Пример: <Link to={`/OpenData/GetDocument.json/${lang}?Code=1`}>/OpenData/GetDocument.json?Code=1</Link></li>
        </ul>
        <Typography>Способы указаны в порядке приорета по возрастанию: те что ниже, перекрывают тех что выше</Typography>
    </Box>
  )
}

const methods = [
    {
        title: 'CheckAvailable',
    },
    {
        title: 'GetClasses',
    },
    {
        title: 'GetProperties',
    },
    {
        title: 'GetDocument',
    },
    {
        title: 'GetDocuments',
    },
    {
        title: 'GetDocumentListByQuery',
    },
    {
        title: 'GetDocumentListById',
    },
    {
        title: 'GetReferenceDocuments',
    },
    {
        title: 'GetEditions',
    },
    {
        title: 'GetClassificators',
    },
    {
        title: 'GetEditionHtml',
    },
    {
        title: 'GetEditionImage',
    },
]